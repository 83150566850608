import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';

function Startuphire(){

return(
<>
<html lang="en">

<head>
  <meta charset="utf-8" />
  <meta content="width=device-width, initial-scale=1.0" name="viewport" />

  <title>BizPage Bootstrap Template</title>
  <meta content="" name="description" />
  <meta content="" name="keywords" />

  {/*} Favicons */}
  <link href="hiring/assets/img/favicon.png" rel="icon" />
  <link href="hiring/assets/img/apple-touch-icon.png" rel="apple-touch-icon" />

  {/*} Google Fonts */}
  <link href="https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,700,700i|Montserrat:300,400,500,700" rel="stylesheet" />

  {/*} Vendor CSS Files */}
  <link href="hiring/assets/vendor/animate.css/animate.min.css" rel="stylesheet" />
  <link href="hiring/assets/vendor/aos/aos.css" rel="stylesheet" />
  <link href="hiring/assets/vendor/bootstrap/css/bootstrap.min.css" rel="stylesheet" />
  <link href="hiring/assets/vendor/bootstrap-icons/bootstrap-icons.css" rel="stylesheet" />
  <link href="hiring/assets/vendor/glightbox/css/glightbox.min.css" rel="stylesheet" />
  <link href="hiring/assets/vendor/swiper/swiper-bundle.min.css" rel="stylesheet" />

  {/*} Template Main CSS File */}
  <link href="hiring/assets/css/style.css" rel="stylesheet" />

  {/*} =======================================================
  * Template Name: BizPage - v5.8.0
  * Template URL: https://bootstrapmade.com/bizpage-bootstrap-business-template/
  * Author: BootstrapMade.com
  * License: https://bootstrapmade.com/license/
  ======================================================== */}
</head>

<body>

  {/*} ======= Header ======= */}
  <header id="header" class="fixed-top d-flex align-items-center header-transparent">
    <div class="container-fluid">

      <div class="row justify-content-center align-items-center">
        <div class="col-xl-11 d-flex align-items-center justify-content-between">
          <h1 class="logo"><a href="index.html">StartupHire</a></h1>
          {/*} Uncomment below if you prefer to use an image logo */}
          {/*} <a href="index.html" class="logo"><img src="assets/img/logo.png" alt="" / class="img-fluid"></a>*/}

          <nav id="navbar" class="navbar">
            <ul>
            {/*}  <li><a class="nav-link scrollto active" href="/">Home</a></li>
              <li><a class="nav-link scrollto" href="#about">About</a></li>
              <li><a class="nav-link scrollto" href="#services">Services</a></li>
              <li><a class="nav-link scrollto " href="#portfolio">Portfolio</a></li>
              <li><a class="nav-link scrollto" href="#team">Team</a></li> */}
              <li><a class="nav-link  " href="#services">Features</a></li>
      {/*}        <li class="dropdown"><a href="#"><span>Drop Down</span> <i class="bi bi-chevron-down"></i></a>
                <ul>
                  <li><a href="#">Drop Down 1</a></li>
                  <li class="dropdown"><a href="#"><span>Deep Drop Down</span> <i class="bi bi-chevron-right"></i></a>
                    <ul>
                      <li><a href="#">Deep Drop Down 1</a></li>
                      <li><a href="#">Deep Drop Down 2</a></li>
                      <li><a href="#">Deep Drop Down 3</a></li>
                      <li><a href="#">Deep Drop Down 4</a></li>
                      <li><a href="#">Deep Drop Down 5</a></li>
                    </ul>
                  </li>
                  <li><a href="#">Drop Down 2</a></li>
                  <li><a href="#">Drop Down 3</a></li>
                  <li><a href="#">Drop Down 4</a></li>
                </ul>
              </li> */}
              <li><a class="nav-link scrollto" href="#contact">Contact</a></li>
            </ul>
            <i class="bi bi-list mobile-nav-toggle"></i>
          </nav>{/*} .navbar */}
        </div>
      </div>

    </div>
  </header>{/*} End Header */}

  {/*} ======= hero Section ======= */}
  <section id="hero">
    <div class="hero-container">
      <div id="heroCarousel" class="carousel slide carousel-fade" data-bs-ride="carousel" data-bs-interval="5000">

        <ol id="hero-carousel-indicators" class="carousel-indicators"></ol>

        <div class="carousel-inner" role="listbox">

          <div class="carousel-item active" style={{backgroundImage: "url(hiring/assets/img/hero-carousel/1.jpg)"}}>
            <div class="carousel-container">
              <div class="container">
                <h2 class="animate__animated animate__fadeInDown">Welcome to StartupHire: Your Gateway to Top Talent</h2>
                <p class="animate__animated animate__fadeInUp">Are you a startup looking for the perfect candidate to join your dynamic team? Or are you a job seeker searching for your dream startup job? Look no further than StartupHire, your premier destination for hiring and job hunting in the startup world. At StartupHire, we understand that startups require a unique approach to hiring, and we've tailored our platform to meet your specific needs.</p>
                <a href="#featured-services" class="btn-get-started scrollto animate__animated animate__fadeInUp">Get Started</a>
              </div>
            </div>
          </div>

          <div class="carousel-item" style={{backgroundImage: "url(hiring/assets/img/hero-carousel/2.jpg)"}}>
            <div class="carousel-container">
              <div class="container">
                <h2 class="animate__animated animate__fadeInDown">At vero eos et accusamus</h2>
                <p class="animate__animated animate__fadeInUp">Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas assumenda est, omnis dolor repellendus. Temporibus autem quibusdam et aut officiis debitis aut.</p>
                <a href="#featured-services" class="btn-get-started scrollto animate__animated animate__fadeInUp">Get Started</a>
              </div>
            </div>
          </div>

          <div class="carousel-item" style={{backgroundImage: "url(hiring/assets/img/hero-carousel/3.jpg)"}}>
            <div class="carousel-container">
              <div class="container">
                <h2 class="animate__animated animate__fadeInDown">Temporibus autem quibusdam</h2>
                <p class="animate__animated animate__fadeInUp">Beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt omnis iste natus error sit voluptatem accusantium.</p>
                <a href="#featured-services" class="btn-get-started scrollto animate__animated animate__fadeInUp">Get Started</a>
              </div>
            </div>
          </div>

          <div class="carousel-item" style={{backgroundImage: "url(hiring/assets/img/hero-carousel/4.jpg)"}}>
            <div class="carousel-container">
              <div class="container">
                <h2 class="animate__animated animate__fadeInDown">Nam libero tempore</h2>
                <p class="animate__animated animate__fadeInUp">Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum.</p>
                <a href="#featured-services" class="btn-get-started scrollto animate__animated animate__fadeInUp">Get Started</a>
              </div>
            </div>
          </div>

          <div class="carousel-item" style={{backgroundImage: "url(hiring/assets/img/hero-carousel/5.jpg)"}}>
            <div class="carousel-container">
              <div class="container">
                <h2 class="animate__animated animate__fadeInDown">Magnam aliquam quaerat</h2>
                <p class="animate__animated animate__fadeInUp">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                <a href="#featured-services" class="btn-get-started scrollto animate__animated animate__fadeInUp">Get Started</a>
              </div>
            </div>
          </div>

        </div>

        <a class="carousel-control-prev" href="#heroCarousel" role="button" data-bs-slide="prev">
          <span class="carousel-control-prev-icon bi bi-chevron-left" aria-hidden="true"></span>
        </a>

        <a class="carousel-control-next" href="#heroCarousel" role="button" data-bs-slide="next">
          <span class="carousel-control-next-icon bi bi-chevron-right" aria-hidden="true"></span>
        </a>

      </div>
    </div>
  </section>{/*} End Hero Section */}



  
  <main id="main">

    {/*} ======= Featured Services Section Section ======= */}
    <section id="featured-services">
      <div class="container">
        <div class="row">

          <div class="col-lg-3 box">
            <i class="bi bi-briefcase"></i>
            <h4 class="title"><a href="">Automated Candidate Matching</a></h4>
            <p class="description">Our advanced candidate rating system and matching algorithm ensure that you find the best talent in the shortest amount of time. We understand that startups move quickly, and we're here to help you keep up.</p>
          </div>

          <div class="col-lg-3 box box-bg">
            <i class="bi bi-card-checklist"></i>
            <h4 class="title"><a href="">Customized Assignments</a></h4>
            <p class="description">Assessing a candidate's skills has never been easier. With our assignments feature, you can create customized tests that allow candidates to showcase their abilities. We even provide a marks tracking system to help you evaluate candidates effectively.</p>
          </div>

          <div class="col-lg-3 box">
            <i class="bi bi-binoculars"></i>
            <h4 class="title"><a href="">Detailed C.V.</a></h4>
            <p class="description">Say goodbye to generic resumes! StartupHire offers one of the most detailed CV creators in the industry. Candidates can record their profiles based on more than nine major sections, giving you a comprehensive view of their skills and experiences. Plus, we provide candidate ratings to help you make informed decisions.</p>
          </div>

          <div class="col-lg-3 box box-bg">
            <i class="bi bi-card-checklist"></i>
            <h4 class="title"><a href="">Application Tracking and Feedback</a></h4>
            <p class="description">Transparency is the name of the game at StartupHire. With our application tracking and feedback system, you can keep tabs on each candidate's progress through the hiring process. Moreover, your feedback will impact the employer's rating on our platform, ensuring that everyone plays fair and transparently.</p>
          </div>

        </div>
      </div>
    </section>{/*} End Featured Services Section */}

    {/*} ======= About Us Section ======= */}
    <section id="about">
      <div class="container">

        <header class="section-header">
          <h3>Why Choose StartupHire?</h3>
          <p>At StartupHire, we understand that time is of the essence for both employers and job seekers in the fast-paced startup ecosystem. That's why we've developed a range of cutting-edge features to streamline the hiring process and connect you with your ideal match in record time.</p>
        </header>

        <div class="row about-cols">

          <div class="col-md-4" data-aos-delay="100">
            <div class="about-col">
              <div class="img">
                <img src="assets/img/about-mission.jpg" alt="" class="img-fluid" />
                <div class="icon"><i class="bi bi-bar-chart"></i></div>
              </div>
              <h2 class="title"><a href="#">Efficiency</a></h2>
              <p>
              Our automated matching system and customized assignments save you time and effort, allowing you to focus on what matters most—growing your startup.
              </p>
            </div>
          </div>

          <div class="col-md-4" data-aos-delay="200">
            <div class="about-col">
              <div class="img">
                <img src="assets/img/about-plan.jpg" alt="" class="img-fluid" />
                <div class="icon"><i class="bi bi-brightness-high"></i></div>
              </div>
              <h2 class="title"><a href="#">Transparency</a></h2>
              <p>
              We believe in creating a transparent hiring environment where both employers and candidates have access to valuable information and feedback.              </p>
            </div>
          </div>

          <div class="col-md-4" data-aos-delay="300">
            <div class="about-col">
              <div class="img">
                <img src="assets/img/about-vision.jpg" alt="" class="img-fluid" />
                <div class="icon"><i class="bi bi-calendar4-week"></i></div>
              </div>
              <h2 class="title"><a href="#">Innovation</a></h2>
              <p>
              We're constantly evolving and adding new features to stay ahead of the curve in the fast-paced startup landscape.         </p>
             </div>
          </div>

        </div>

      </div>
    </section>{/*} End About Us Section */}


    {/*} ======= Services Section ======= */}
    <section id="services">
      <div class="container">

        <header class="section-header">
          <h3>More Features</h3>
          <p>Unlock the Power of StartupHire: Exploring an Array of Cutting-Edge Features for Your Ultimate Hiring and Career Success</p>
        </header>

        <div class="row">

          <div class="col-lg-4 col-md-6 box">
            <div class="icon"><i class="bi bi-briefcase"></i></div>
            <h4 class="title"><a href="">Advanced Candidate Search</a></h4>
            <p class="description">A robust search engine that allows employers and recruiters to filter candidates based on specific criteria such as skills, experience, location, and more.</p>
          </div>
          <div class="col-lg-4 col-md-6 box">
            <div class="icon"><i class="bi bi-card-checklist"></i></div>
            <h4 class="title"><a href="">Skill Testing</a></h4>
            <p class="description">An extensive library of skill assessment tests that candidates can take to showcase their expertise in various domains.</p>
          </div>
          <div class="col-lg-4 col-md-6 box">
            <div class="icon"><i class="bi bi-bar-chart"></i></div>
            <h4 class="title"><a href="">Dynamic Job Matchings</a></h4>
            <p class="description">A system that continuously updates job matches as candidates acquire new skills or experiences.</p>
          </div>
          <div class="col-lg-4 col-md-6 box">
            <div class="icon"><i class="bi bi-binoculars"></i></div>
            <h4 class="title"><a href="">Automated Status Updates</a></h4>
            <p class="description">Real-time updates on the status of job applications, ensuring candidates are informed at each stage.</p>
          </div>
          <div class="col-lg-4 col-md-6 box">
            <div class="icon"><i class="bi bi-brightness-high"></i></div>
            <h4 class="title"><a href="">Performance Analytics</a></h4>
            <p class="description">Insights into the effectiveness of different recruitment strategies, helping employers optimize their hiring processes.</p>
          </div>
          <div class="col-lg-4 col-md-6 box">
            <div class="icon"><i class="bi bi-calendar4-week"></i></div>
            <h4 class="title"><a href="">Employer Ratings</a></h4>
            <p class="description">Allowing candidates to rate and review employers, fostering a transparent and accountable hiring ecosystem.</p>
          </div>

        </div>

      </div>
    </section>{/*} End Services Section */}

    {/*} ======= Call To Action Section ======= */}
    <section id="call-to-action">
      <div class="container text-center">
        <h3>Join the Startup Revolution</h3>
        <p>Start Your Journey to Success with StartupHire Today!</p>
        <a class="cta-btn" href="#">Get Started</a>
      </div>
    </section>{/*} End Call To Action Section */}

    </main>


    {/*} ======= Contact Section ======= */}
    <section id="contact" class="section-bg">
      <div class="container">

        <div class="section-header">
          <h3>Contact Us</h3>
          <p>Ask your queries or send your feedbacks to us.</p>
        </div>

        <div class="row contact-info">

          <div class="col-md-4">
            <div class="contact-address">
              <i class="bi bi-geo-alt"></i>
              <h3>Address</h3>
              <address>LIG C-5 90 Quarter Sanjivani Nagar, Jabalpur<br/>
                       District: Jabalpur (Madhya Pradesh)<br />
                       Pincode: 482001 (India)</address>
            </div>
          </div>

          <div class="col-md-4">
            <div class="contact-phone">
              <i class="bi bi-phone"></i>
              <h3>Phone Number</h3>
              <p><a href="tel:+918480002413">+91 84800 02413</a></p>
            </div>
          </div>

          <div class="col-md-4">
            <div class="contact-email">
              <i class="bi bi-envelope"></i>
              <h3>Email</h3>
              <p><a href="mailto:help.startupconnect@gmail.com">help.startupconnect@gmail.com</a></p>
            </div>
          </div>

        </div>

        <div class="form">
          <form action="forms/contact.php" method="post" role="form" class="php-email-form">
            <div class="row">
              <div class="form-group col-md-6">
                <input type="text" name="name" class="form-control" id="name" placeholder="Your Name" required />
              </div>
              <div class="form-group col-md-6">
                <input type="email" class="form-control" name="email" id="email" placeholder="Your Email" required />
              </div>
            </div>
            <div class="form-group">
              <input type="text" class="form-control" name="subject" id="subject" placeholder="Subject" required />
            </div>
            <div class="form-group">
              <textarea class="form-control" name="message" rows="5" placeholder="Message" required></textarea>
            </div>
            <div class="my-3">
              <div class="loading">Loading</div>
              <div class="error-message"></div>
              <div class="sent-message">Your message has been sent. Thank you!</div>
            </div>
            <div class="text-center"><button type="submit">Send Message</button></div>
          </form>
        </div>

      </div>
    </section>{/*} End Contact Section */}

  

  {/*} ======= Footer ======= */}
  <footer id="footer">
  {/*}  <div class="footer-top">
      <div class="container">
        <div class="row">

          <div class="col-lg-3 col-md-6 footer-info">
            <h3>BizPage</h3>
            <p>Cras fermentum odio eu feugiat lide par naso tierra. Justo eget nada terra videa magna derita valies darta donna mare fermentum iaculis eu non diam phasellus. Scelerisque felis imperdiet proin fermentum leo. Amet volutpat consequat mauris nunc congue.</p>
          </div>

          <div class="col-lg-3 col-md-6 footer-links">
            <h4>Useful Links</h4>
            <ul>
              <li><i class="bi bi-chevron-right"></i> <a href="#">Home</a></li>
              <li><i class="bi bi-chevron-right"></i> <a href="#">About us</a></li>
              <li><i class="bi bi-chevron-right"></i> <a href="#">Services</a></li>
              <li><i class="bi bi-chevron-right"></i> <a href="#">Terms of service</a></li>
              <li><i class="bi bi-chevron-right"></i> <a href="#">Privacy policy</a></li>
            </ul>
          </div>

          <div class="col-lg-3 col-md-6 footer-contact">
            <h4>Contact Us</h4>
            <p>
              A108 Adam Street <br />
              New York, NY 535022<br />
              United States <br />
              <strong>Phone:</strong> +1 5589 55488 55<br />
              <strong>Email:</strong> info@example.com<br />
            </p>

            <div class="social-links">
              <a href="#" class="twitter"><i class="bi bi-twitter"></i></a>
              <a href="#" class="facebook"><i class="bi bi-facebook"></i></a>
              <a href="#" class="instagram"><i class="bi bi-instagram"></i></a>
              <a href="#" class="instagram"><i class="bi bi-instagram"></i></a>
              <a href="#" class="linkedin"><i class="bi bi-linkedin"></i></a>
            </div>

          </div>

          <div class="col-lg-3 col-md-6 footer-newsletter">
            <h4>Our Newsletter</h4>
            <p>Tamen quem nulla quae legam multos aute sint culpa legam noster magna veniam enim veniam illum dolore legam minim quorum culpa amet magna export quem marada parida nodela caramase seza.</p>
            <form action="" method="post">
              <input type="email" name="email" /><input type="submit" value="Subscribe" />
            </form>
          </div>

        </div>
      </div>
    </div> */}

    <div class="container">
      <div class="copyright">
        &copy; <strong>2023 Technomate Startup Services Pvt. Ltd.</strong> All Rights Reserved.
      </div>
      <div class="credits">
        {/*}
        All the links in the footer should remain intact.
        You can delete the links only if you purchased the pro version.
        Licensing information: https://bootstrapmade.com/license/
        Purchase the pro version with working PHP/AJAX contact form: https://bootstrapmade.com/buy/?theme=BizPage
      */}
        Designed by <a href="https://bootstrapmade.com/">BootstrapMade</a>
      </div>
    </div>
  </footer>{/*} End Footer */}

  <a href="#" class="back-to-top d-flex align-items-center justify-content-center"><i class="bi bi-arrow-up-short"></i></a>
  {/*} Uncomment below i you want to use a preloader */}
  {/*} <div id="preloader"></div> */}

  {/*} Vendor JS Files */}
  <script src="hiring/assets/vendor/purecounter/purecounter.js"></script>
  <script src="hiring/assets/vendor/aos/aos.js"></script>
  <script src="hiring/assets/vendor/bootstrap/js/bootstrap.bundle.min.js"></script>
  <script src="hiring/assets/vendor/glightbox/js/glightbox.min.js"></script>
  <script src="hiring/assets/vendor/isotope-layout/isotope.pkgd.min.js"></script>
  <script src="hiring/assets/vendor/swiper/swiper-bundle.min.js"></script>
  <script src="hiring/assets/vendor/waypoints/noframework.waypoints.js"></script>
  <script src="hiring/assets/vendor/php-email-form/validate.js"></script>

  {/*} Template Main JS File */}
  <script src="hiring/assets/js/main.js"></script>

</body>

</html>
</>
);

}

export default Startuphire;