import React from 'react';
import {useState,useEffect,Component} from 'react';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';
import logo from './logo.svg';
import './App.css';
import Routestosite from './routestosite';




function App() {
  return (
    <div className="App">
     <Routestosite></Routestosite>
    </div>
  );
}

export default App;
