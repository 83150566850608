import React from 'react';
import {useState,useEffect,Component} from 'react';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';
import axios from '../axios.js';
import Navbar from './Extracomponents/navbar.js';


   // Setting Values fom Session Storage
   const userlogstatus=sessionStorage.getItem("userloginstatus00");//loginstatus
   //const loguser=sessionStorage.getItem("userusername00");//username
   const userlogtype=sessionStorage.getItem("userlogintype00");//logintype
   const userlogname=sessionStorage.getItem("userloginname00");//loginname
   const userlogadminid=sessionStorage.getItem("userloginadminid00");//loginadminid

   
function Selection(){



   //Sessions Not Set
   if(((userlogstatus=="false")||(userlogstatus==null))&&/*(loguser==null)&&*/(userlogadminid==null)){
    //if(((logstatus=="true")||(logstatus==null))&&(loguser==null)&&(logadminid==null)){

  /*  sessionStorage.removeItem("username00"); */
    sessionStorage.removeItem("userloginstatus00");
    sessionStorage.removeItem("userlogintype00");
    sessionStorage.removeItem("userloginname00");
    sessionStorage.removeItem("userloginadminid00");
   
    alert("Session Expired! Login Again.");
    window.location.replace("/");
  
  }
  //Sessions Set
  else{





  
      //firstname
      const[firstname,setfirstname]=useState();const updatefirstname = (event) => { setfirstname(event.target.value);};
      //lastname
      const[lastname,setlastname]=useState();const updatelastname = (event) => { setlastname(event.target.value);};
      //email
      const[email,setemail]=useState();const updateemail = (event) => { setemail(event.target.value);};
      //contact
      const[contact,setcontact]=useState();const updatecontact = (event) => { setcontact(event.target.value);};
      //whatsapp
      const[whatsapp,setwhatsapp]=useState();const updatewhatsapp = (event) => { setwhatsapp(event.target.value);};
      //nameofmother
      const[nameofmother,setnameofmother]=useState();const updatenameofmother = (event) => { setnameofmother(event.target.value);};
      //nameoffather
      const[nameoffather,setnameoffather]=useState();const updatenameoffather = (event) => { setnameoffather(event.target.value);};
      //gender
      const[gender,setgender]=useState();const updategender = (event) => { setgender(event.target.value);};
      //dateofbirth
      const[dateofbirth,setdateofbirth]=useState();const updatedateofbirth = (event) => { setdateofbirth(event.target.value);};
      //siblingstatus
      const[siblingstatus,setsiblingstatus]=useState();const updatesiblingstatus = (event) => { setsiblingstatus(event.target.value);};  
      //siblings
      const[siblings,setsiblings]=useState();const updatesiblings = (event) => { setsiblings(event.target.value);};        

      
        //ERROR VARIABLES 
        const[error1,seterror1]=useState();
        const[error2,seterror2]=useState();
        const[error3,seterror3]=useState();
        const[error4,seterror4]=useState();
        const[error5,seterror5]=useState();
        const[error6,seterror6]=useState();
        const[error7,seterror7]=useState();


  
    return(
<>
<html lang="en">
  <head>
    {/* Required meta tags */}
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />

    {/* Bootstrap CSS */}
    <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.3.1/dist/css/bootstrap.min.css" integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T" crossorigin="anonymous" />

    <title>Selection</title>
  </head>
  <body>
<Navbar/>

{/* PERSONAL DETAILS SECTION START */}

<div class="content-wrapper">

<div class="col-12">


                <div class="card">
                  <div class="card-body">
                    <h2 >Continue to our new Hiring Portal</h2>
                    <div class="form-sample" style={{textAlign:"left"}}></div>

                      <p class="card-description"> Be amongst the first in industries to test and utilize our hiring portal.</p>
<br/><br/>
                   
<div class="card-deck">
  <div class="card">
    <img class="card-img-top" src="https://cdn.pixabay.com/photo/2019/01/19/19/22/recruitment-3942378_1280.jpg" style={{height:"30vh"}} alt="Card image cap" />
    <div class="card-body">
      <h5 class="card-title">Post a Job or Internship</h5>
      <p class="card-text">Register now and get started wth your journey to hire and retain quality talent amongst 1000s of capable candidates.</p>
      <button type="button" class="btn btn-outline-info">Get Started</button>
      <p class="card-text"><small class="text-muted">Individuals can also post their requirments*</small></p>
    </div>
  </div>
  <div class="card">
    <img class="card-img-top" src="https://cdn.pixabay.com/photo/2020/01/21/17/17/hr-process-4783430_1280.png" style={{height:"30vh"}} alt="Card image cap" />
    <div class="card-body">
      <h5 class="card-title">Find a Job or Internship</h5>
      <p class="card-text">Either you are a student or a working professional. We have got you covered. Register now and apply to jobs or internships matching your skills and expertise.</p>
      <button type="button" class="btn btn-outline-info">Get Started</button>
      <p class="card-text"><small class="text-muted">Freshers can also apply for open positions</small></p>
    </div>
  </div>
  <div class="card">
    <img class="card-img-top" src="https://cdn.pixabay.com/photo/2023/06/01/07/59/call-8033050_640.png" style={{height:"30vh"}} alt="Card image cap" />
    <div class="card-body">
      <h5 class="card-title">Contact us for support</h5>
      <p class="card-text">If you are having any questions or want support to get started. Feel free to reach out to us and we will be happy to assist you get onboard with us.</p>
      <button type="button" class="btn btn-outline-info">Contact Us</button>
      <p class="card-text"><small class="text-muted">you can also send your queries on mail</small></p>
    </div>
  </div>
</div>
                    </div>
                  </div>
                </div>
              </div>

              
              {/* PERSONAL DETAILS SECTION END */}
              </body>
              </html>
</>
);
    }
}

export default Selection;