import React from 'react';
import {useState,useEffect,Component} from 'react';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';





/* Import home from File */



//HIRING PORTAL
import HiringLogin from './Hiringportal/login';
import Selection from './Hiringportal/selection';
import Candidateform from './Hiringportal/candidateform';
import Candidatedashboard from './Hiringportal/Candidate/dashboard';
import Candidatesaveddashboard from './Hiringportal/Candidate/saveddashboard';
import Candidateapplieddashboard from './Hiringportal/Candidate/applieddashboard';
import Candidateapply from './Hiringportal/Candidate/apply';
import Candidatecreateprofile from './Hiringportal/Candidate/Candidateprofile';


import Employerdashboard from './Hiringportal/Employer/dashboard';
import Employerjobsdashboard from './Hiringportal/Employer/jobs';
import Employercreatejob from './Hiringportal/Employer/createjob';
import Employerviewapplications from './Hiringportal/Employer/viewapplications';
import Employercreateapplicationquestions from './Hiringportal/Employer/createapplicationquestions';
import Employerinvitations from './Hiringportal/Employer/invitations';
import Employerassignments from './Hiringportal/Employer/assignments';
import Employercreateassignment from './Hiringportal/Employer/createassignment';
import Employerviewresume from './Hiringportal/Employer/resume';
import Employerviewapplication from './Hiringportal/Employer/viewapplication';
import Employerviewcandidateassignment from './Hiringportal/Employer/viewcandidateassignment';
import Employerviewassignment from './Hiringportal/Employer/viewassignment';
import Employercreatecompany from './Hiringportal/Employer/createcompany';
import Employercompanies from './Hiringportal/Employer/companies';
import Candidatecompanies from './Hiringportal/Candidate/companies';


//import Hiredashboard from './Hire/dashboard';
//import Login1 from './Hire/dashboard1';

//SUPPORTING DOCUMENTS
import Disclaimer from './Supportingfiles/disclaimer';
import Termsandconditions from './Supportingfiles/termsandconditions';
import Refundpolicy from './Supportingfiles/refundpolicy';
import Privacypolicy from './Supportingfiles/privacypolicy';


import Startuphire from './hiringhome';










function Routestosite(){

    return(
<>
<Router>
   <Routes>

   <Route exact path='/new-home' element={<Startuphire />} />
   <Route exact path='/home' element={<Startuphire/>} />
   <Route exact path='/' element={<Startuphire />} />



    /* DIFFERENT PORTAL HOME PAGES REDIRECT */
    <Route exact path='startuphire-home' element={<Startuphire />} /> 

   /*    SERVICES PORTAL    */
  {/* <Route exact path='/register' element={<Register />} />
   <Route exact path='/login' element={<Login />} />
  */}


   /* HIRING PORTAL */
   <Route exact path='/hiring-login' element={<HiringLogin />} />
   <Route exact path='/selection' element={<Selection />} />
   <Route exact path='/candidatedetails' element={<Candidateform />} />

   <Route exact path='/candidate-dashboard' element={<Candidatedashboard />} />
   <Route exact path='/candidate-saved' element={<Candidatesaveddashboard />} />
   <Route exact path='/candidate-applied' element={<Candidateapplieddashboard />} />
   <Route exact path='/candidate-apply' element={<Candidateapply/>} />
   <Route exact path='/candidate-create-profile' element={<Candidatecreateprofile/>} />

   <Route exact path='/employer-dashboard' element={<Employerdashboard />} />
   <Route exact path='/employer-jobs' element={<Employerjobsdashboard />} />
   <Route exact path='/employer-create-job' element={<Employercreatejob />} />
   <Route exact path='/employer-view-applications' element={<Employerviewapplications />} />
   <Route exact path='/employer-create-application-questions' element={<Employercreateapplicationquestions />} />
   <Route exact path='/employer-invitations' element={<Employerinvitations />} />
   <Route exact path='/employer-assignments' element={<Employerassignments />} />
   <Route exact path='/employer-create-assignment' element={<Employercreateassignment />} />
   <Route exact path='/employer-view-resume' element={<Employerviewresume/>} />
   <Route exact path='/employer-view-application' element={<Employerviewapplication/>} />
   <Route exact path='/employer-view-candidate-assignment' element={<Employerviewcandidateassignment/>} />
   <Route exact path='/employer-view-assignment' element={<Employerviewassignment/>} />
   <Route exact path='/employer-create-company' element={<Employercreatecompany />} />
   <Route exact path='/employer-companies' element={<Employercompanies />} />
   <Route exact path='/candidate-companies' element={<Candidatecompanies />} />


   /* SUPPORTING DOCUMENTS */
   <Route exact path='/disclaimer' element={<Disclaimer />} />
   <Route exact path='/termsandconditions' element={<Termsandconditions />} />
   <Route exact path='/refundandcancellation-policy' element={<Refundpolicy />} />
   <Route exact path='/privacy-policy' element={<Privacypolicy />} />


   </Routes>
   </Router>
</>
    );


}

export default Routestosite;